import showcaseimg from "../../../assets/images/showcaseimage.jpg";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../../App.css";
// import whitespace from '../../../assets/css/custom.css';
import Dept1 from "../../../assets/images/order-nowicon-01.svg";
import Dept2 from "../../../assets/images/join-eloyalty-01.svg";
import Dept3 from "../../../assets/images/visit-our-website-01.svg";
import SiLogo from "../../../assets/images/servingintel-logo-bw.svg";
import { FaHeart } from "react-icons/fa";
import { Container, Row, Col, Button, h1, p, h6, hr } from "react-bootstrap";
import Logo from "../../../assets/images/logo.png";
import { useHistory } from "react-router-dom";
import { useCookies } from "react-cookie";
import parseHtml from "html-react-parser";
import React, { useState, useEffect } from "react";
const serverId = process.env.REACT_APP_SERVER_ID
const siteId = process.env.REACT_APP_SITE_ID

export const Mobile = () => {
    
useEffect(() => {
document.title = "Welcome"
}, [])    
   
  const history = useHistory();
  const [cookieSICName, setCookieSICName, removeCookieSICName] = useCookies([
    "sic_name",
  ]);

  const getCookie = (key) => {
    var keyValue = document.cookie.match("(^|;) ?" + key + "=([^;]*)(;|$)");
    return keyValue ? keyValue[2] : null;
  };

  const setCookie = (key, value) => {
    const expires = new Date();
    expires.setTime(expires.getTime() + 365 * 24 * 60 * 60 * 1000);
    document.cookie =
      key + "=" + value + ";expires=" + expires.toUTCString() + ";path=/";
  };

  useEffect(() => {
    var verifyOrderProcess = new EventSource(
      "https://ecommv2.servingintel.com/postpayment/verifyorderprocess/"+siteId+"/" +
        getCookie("orderId")+"?server_id="+serverId
    );
    verifyOrderProcess.onmessage = function (event) {
      if (event.data === 1) {
        verifyOrderProcess.close();
        setCookie("orderId", "");
      }
    };

    var sourcePWA = new EventSource(
      "https://ecommv2.servingintel.com/pwa/check_update"
    );
    sourcePWA.onmessage = function (event) {
      var u_items = JSON.parse(event.data);
      //console.log(u_items[0].utime);
      if (u_items[0].time != "") {
        if (
          sessionStorage.getItem("pwa_update_date_time") !== u_items[0].time
        ) {
          caches.delete("appV1");
          sessionStorage.setItem("pwa_update_date_time", u_items[0].time);
          window.location.reload();
        }
      }
    };
  }, []);

  return (
    <div className="MobileContImg">
      <Col className="center" xs={12}>
        <h3 className="white">
          {cookieSICName.sic_name
            ? parseHtml("I knew you'd come, <b>" + cookieSICName.sic_name + "!</b>")
            : "Welcome"}{" "}
          to
        </h3>
        <img className="App-logo" src={Logo} alt="Leo's Coney Island" />
        <h3 className="white" style={{ padding: "5px" }}>
          Our famous coney island, fries and a mini Greek salad.
        </h3>
      </Col>
      <div className="dept-btn-cont center">
        <Col xs={12}>
          <a href="/menu" target="_self">
            <div className="col-lg-12">
              <div className="our-dept-main">
                <div className="dept-front dept-1">
                  <img
                    className=""
                    src={Dept1}
                    alt="Order Now"
                    width="72"
                    height="auto"
                  />
                  <h3 className="white">Order Now</h3>
                </div>
              </div>
            </div>
          </a>
        </Col>
        <Col xs={12}>
          <a href="#" target="_self">
            <div className="col-lg-12">
              <div className="our-dept-main">
                <div className="dept-front dept-2">
                  <img
                    className=""
                    src={Dept2}
                    alt="Digital Wallet"
                    width="72"
                    height="auto"
                  />
                  <h3 className="white">Digital Wallet</h3>
                </div>
              </div>
            </div>
          </a>
        </Col>
        <Col xs={12}>
          <a href="#" onClick={() => history.push("/home")} target="_self">
            <div className="col-lg-12">
              <div className="our-dept-main">
                <div className="dept-front dept-3">
                  <img
                    className=""
                    src={Dept3}
                    alt="Order Now"
                    width="72"
                    height="auto"
                  />
                  <h3 className="white">Visit Our Website</h3>
                </div>
              </div>
            </div>
          </a>
        </Col>

        <p className="white heart">
          Made with <FaHeart /> by
        </p>
        <img className="App-logo" src={SiLogo} alt="ServingIntel" />
      </div>

      <col className="whitespace"></col>
    </div>
  );
};

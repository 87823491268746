import React, { useState, useEffect } from "react";
import { SketchPicker } from "react-color";
import { Typography, Button } from "@material-ui/core";
import { db } from "../../firebase/config";
import { useSelector, useDispatch } from "react-redux";
import { setLoading } from "../../redux/publicReducer/actions";
import { toast } from "react-toastify";
export const ColorScheme = () => {
  const [initialColor, setInitialColor] = useState("#000000");
  const { user } = useSelector((state) => state.public);
  const dispatch = useDispatch();

  const handleSubmitColor = () => {
    dispatch(setLoading(true));
    db.collection("admins")
      .doc(user.id)
      .update({ restaurantColor: initialColor })
      .then(() => {
        dispatch(setLoading(false));
        toast.success("Color scheme updated");
      });
  };

  useEffect(() => {
    const unsub = db
      .collection("admins")
      .doc(user.id)
      .onSnapshot((snapshot) => {
        if (snapshot.data()?.restaurantColor) {
          setInitialColor(snapshot.data()?.restaurantColor);
        }
      });

    return unsub;
  }, []);

  return (
    <>
      <Typography style={{ marginBottom: "1rem" }} variant="h5">
        Restaurant Color Scheme
      </Typography>
      <div>
        <SketchPicker
          color={initialColor}
          onChange={(e) => setInitialColor(e)}
          style={{ minWidth: "50vw" }}
        />
      </div>
      <Button
        variant="contained"
        color="primary"
        style={{ marginTop: "1rem" }}
        onClick={() => {
          handleSubmitColor();
        }}
      >
        Save Color Scheme
      </Button>
    </>
  );
};

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import {
  Paper,
  Tabs,
  Tab,
  Typography,
  Box,
  Button,
  FormLabel,
  useMediaQuery,
  Container,
  Grid,
} from "@material-ui/core";
import AutorenewIcon from "@material-ui/icons/Autorenew";

import { db, auth } from "../../firebase/config";
import { toast } from "react-toastify";
import Badge from "@material-ui/core/Badge";
import FastfoodIcon from "@material-ui/icons/Fastfood";

import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { NewClientOrders } from "./NewClientOrders";
import { ClientCompletedOrders } from "./ClientCompletedOrders";
import { ClientCancelledOrders } from "./ClientCancelledOrders";

import CancelIcon from "@material-ui/icons/Cancel";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

export function OrderHistory() {
  const isMobile = useMediaQuery("(max-width:600px)");
  const { user } = useSelector((state) => state.public);
  const history = useHistory();
  const dispatch = useDispatch();

  const classes = useStyles();
  const myOrdersRef = db.collection("orders").where("user", "==", user.id);
  const [myOrders, setMyOrders] = useState([]);
  const [value, setValue] = React.useState(0);
  const [count, setCount] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    const unsub = myOrdersRef.onSnapshot((snapshot) => {
      setMyOrders(snapshot.docs.map((doc) => doc.data()));
    });

    return unsub;
  }, []);

  return (
    <>
      <Grid container alignItems="center" justify="center">
        <Grid item xs={12} sm={6}>
          <Grid container alignItems="center" spacing={1}>
            <Grid item xs={12}>
              <Typography
                variant="h6"
                style={{ fontWeight: 800, paddingLeft: "1rem" }}
              >
                Order History
              </Typography>
            </Grid>
            {myOrders.map((order) => {
              return (
                <Grid item xs={12}>
                  <Paper
                    style={{
                      padding: "1rem",
                      borderTop: `2px solid #cecece`,
                    }}
                    square
                    elevation={0}
                  >
                    <Grid container spacing={1}>
                      <Grid item xs={3}>
                        <img
                          src={order.currentRestaurant?.image}
                          style={{ width: "100%", borderRadius: "25px" }}
                        />
                      </Grid>
                      <Grid item xs={5}>
                        <Typography variant="subtitle1">
                          {order.currentRestaurant?.restaurantName}
                        </Typography>
                        <Typography
                          variant="subtitle2"
                          style={{ color: "gray" }}
                        >
                          {order.currentRestaurant?.description}
                        </Typography>
                        <Grid container>
                          {order.orders.map((o, i) => {
                            return (
                              <Grid item xs={12} key={i}>
                                <Typography variant={"caption"}>
                                  {o.quantity}x {o.productName}
                                </Typography>
                              </Grid>
                            );
                          })}
                        </Grid>
                      </Grid>
                      <Grid item xs={4}>
                        <Button
                          fullWidth
                          color="primary"
                          variant="contained"
                          style={{ borderRadius: "20px" }}
                          onClick={() => {
                            localStorage.setItem(
                              `${order.restaurantId}_cart`,
                              JSON.stringify(order.orders)
                            );
                            history.push(`/${order.restaurantId}/checkout`);
                          }}
                        >
                          Reorder
                        </Button>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
              );
            })}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

import React, { useEffect, useState } from "react";

import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
import Select from "react-select";
import { useForm, Controller } from "react-hook-form";
import * as Yup from "yup";
import { Form, Input } from "../../components";
import { yupResolver } from "@hookform/resolvers/yup";
import { db } from "../../firebase/config";
import { toast } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";
import { setLoading } from "../../redux/publicReducer/actions";

export const ModifierModal = ({
  open,
  setOpen,
  selectedEdit,
  setSelectedEdit,
}) => {
  const { user } = useSelector((state) => state.public);
  const dispatch = useDispatch();
  const productOptionsRef = db
    .collection("admins")
    .doc(user.id)
    .collection("productOptions");
  console.log(selectedEdit, "selected edit");
  const modOptions = [
    { label: "Checkbox", value: "CHECKBOX" },
    { label: "Text", value: "TEXT" },
    { label: "Radio", value: "RADIO" },
    { label: "Dropdown", value: "DROPDOWN" },
    { label: "Textarea", value: "TEXTAREA" },
  ];
  const initialValues = {
    optionName: "",
    type: "",
  };

  const validationSchema = Yup.object({
    optionName: "",
    type: Yup.object({
      label: Yup.string().required("label is required"),
      value: Yup.string().required("value is required"),
    }).required("Option type is required"),
  });

  const methods = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
  });

  const { errors, reset } = methods;

  const handleSubmit = (data) => {
    setOpen(false);
    dispatch(setLoading(true));
    if (selectedEdit?.id) {
      productOptionsRef
        .doc(selectedEdit.id)
        .update({ ...data, type: data.type?.value, label: data?.type?.label })
        .then(() => {
          toast.success("Modifier Edited");
          dispatch(setLoading(false));
          reset();
        });
    } else {
      productOptionsRef
        .add({ ...data, type: data.type?.value, label: data?.type?.label })
        .then(() => {
          toast.success("Modifier added");
          dispatch(setLoading(false));

          reset();
        });
    }
    setOpen(false);
  };

  useEffect(() => {
    console.log(selectedEdit);

    if (selectedEdit?.optionName) {
      reset({
        ...selectedEdit,
        type: { label: selectedEdit.label, value: selectedEdit.type },
      });
    } else {
      reset(initialValues);
    }
  }, [selectedEdit]);
  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      open={open}
      onClose={() => {
        setSelectedEdit(null);
        setOpen(false);
      }}
    >
      <Form provider={methods} onSubmit={handleSubmit}>
        <DialogTitle>
          {selectedEdit?.optionName ? "Edit Modifier" : "Add a modifier"}
        </DialogTitle>

        <DialogContent dividers style={{ minHeight: "30vh" }}>
          <Input name="optionName" label="Parent Modifier" />
          <Controller
            as={<Select placeholder="Select a modifier's type" />}
            name="type"
            options={modOptions}
            control={methods.control}
          />
          <p style={{ color: "red" }}>{errors?.type && errors.type.message}</p>
        </DialogContent>
        <DialogActions>
          <Button type="submit" variant="contained" color="primary">
            {selectedEdit?.optionName ? "Edit Modifier" : "Add modifier"}
          </Button>
        </DialogActions>
      </Form>
    </Dialog>
  );
};

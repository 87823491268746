import React, { useState, useStyles } from 'react';
import { useForm } from "react-hook-form";
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Swal from "sweetalert2";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { useSelector, useDispatch } from "react-redux";
import { useHistory, useRouteMatch } from "react-router-dom";

import { setLoading } from "../../../redux/publicReducer/actions";

import { setOrderSession } from "../../../redux/cartReducer/actions";
import { giftcardService } from "../../../services"

export const GiftCardAsPayment = ({ setOpen }) => {

    const { 
        selectedAddress,
        orderProcessingDate, 
        orderProcessingType
    } = useSelector((state) => state.public);

    const { cartItems, tips, selectedPayment, totalPriceItems, orderSession, promoCode, giftCardBalance } = useSelector((state) => state.cart);

    const history = useHistory();
    const match = useRouteMatch();
    const dispatch = useDispatch();

    // const [giftcardBalance, setGiftcardBalance] = useState("");
    const [buttonLabel, setButtonLabel] = useState("Check Balance");
    const [giftCardInfo, setGiftCardInfo] = useState({})

    const { register, handleSubmit, formState: { errors } } = useForm();
    
    const onPayment = () => {
        if (!!giftCardInfo) {

            if (totalPriceItems > giftCardBalance) {
                setOpen(false);
                history.push("/payment-wpay/"); 
            } else {

                let payments = [{
                    "payment-type": "Gift Card",
                    amount: totalPriceItems,
                    tip: tips,
                }]

                const params = {
                    cartItems: cartItems,
                    selectedAddress: selectedAddress,
                    total: totalPriceItems,
                    tips: tips,
                    promoCode: promoCode,
                    pid: '',
                    payments: payments,
                    subTotal: totalPriceItems,
                    instructions: sessionStorage.getItem("instuctions"),
                    orderProcessingType: orderProcessingType ? "pickup" : "dine-in",
                    orderProcessingDate: orderProcessingDate,
                    orderId: 0
                }
            
                const setOrders = giftcardService.setSessionOrders(params);
                setOrders.then(res => { 
                    dispatch(setOrderSession(res.data));
                    const handleGiftCardPayment = giftcardService.handleGiftCardPayment({
                        order_id: res.data.order_id,
                        user_id: res.data.user_id,
                        data: window.btoa(giftCardInfo.giftcard_number + "-" + giftCardInfo.giftcard_cvc)
                    });
                    handleGiftCardPayment.then(res => {
                        window.localStorage.clear()
                        dispatch({ type: "SET_CART", payload: [] })
                        dispatch(setLoading(false));
                        alert('Payment using gift card is successful.')
                        setTimeout(function(){ history.push("/menu") }, 2000);
                    });
                });
            }
        }
    }

    const onSubmit = data => {
            
        const response = giftcardService.checkGiftCardbalance(data);
        response.then(res => {

            let balanceAmount = 0;
            balanceAmount = res.data.BalanceAmount;

            dispatch({type: "SET_GIFT_CARD_BALANCE", payload: parseFloat(balanceAmount)})

            /*setGiftcardBalance("$" + balanceAmount)*/
            setGiftCardInfo(data)
        })
    };

    const useStyles = makeStyles((theme) => ({
        paper: {
            marginTop: theme.spacing(8),
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },
        avatar: {
            margin: theme.spacing(1),
            backgroundColor: theme.palette.secondary.main,
        },
        form: {
            width: '100%', // Fix IE 11 issue.
            marginTop: theme.spacing(3),
        },
        submit: {
            margin: theme.spacing(3, 0, 2),
        },
    }));

    const classes = useStyles();

    return (
        <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        
        <Typography component="h1" variant="h5">
            ${giftCardBalance}
        </Typography>

        <form id="giftcardForm" className={classes.form} onSubmit={handleSubmit(onSubmit)} noValidate>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="giftcardAccountNo"
                label="Gift Card Account Number"
                name="giftcard_number"
                autoComplete="lname"
                inputRef={register}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="giftcardCvv"
                label="Gift Card CVV"
                name="giftcard_cvc"
                autoComplete="giftcardCvc"
                inputRef={register}
              />
            </Grid>

            <input name="form_action" type="hidden" {...register("form_action")} defaultValue='test'   /> 


          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            style={{
                padding: "11px 0rem",
                borderRadius: "10px",
                backgroundColor: "#ff0000",
                boxShadow: "none",
                fontSize: "16px",
                fontWeight: "bold",
                color:  "#fff",
                letterSpacing: "1px",
            }} 
            className={classes.submit}
          >
            {buttonLabel}
          </Button>
          
        </form>

        <Button id="onPay" fullWidth variant="contained" 
                color="secondary"
                style={{
                      padding: "11px 0rem",
                      borderRadius: "10px",
                      backgroundColor: "#ff0000",
                      boxShadow: "none",
                      fontSize: "16px",
                      fontWeight: "bold",
                      color:  "#fff",
                      letterSpacing: "1px",
                }} 
                onClick={onPayment}>Proceed Payment</Button>
      </div>
      <Box mt={5}>

      </Box>
    </Container>
    )
}
import React from "react";
import PacmanLoader from "react-spinners/PacmanLoader";
import { Backdrop } from "@material-ui/core";
export const Loader = ({ open }) => {
  return (
    <Backdrop
      open={open}
      style={{ zIndex: 1000, backgroundColor: "black", opacity: "0.8" }}
    >
      <PacmanLoader color="#ffffff" loading={open} size={15} />
    </Backdrop>
  );
};

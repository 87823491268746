import axios from "axios";
import { fetchWrapper } from '../utils/fetch-wrapper';

export const rewardsService = {
    checkRewardsBalance,
    handleRewardsPayment,
    setSessionOrders
};

// const baseUrl = `${apiUrl}/users`;
// const baseUrl = "http://dev.siapi.com/public/";
const baseUrl = "https://ecommv2.servingintel.com/";
const siteId = 89;

async function checkRewardsBalance(params) {
    let formData = new URLSearchParams();
    formData.append('email', params.email)
    // return await fetchWrapper.post(`${baseUrl}rewards/rewards/remaining`, formData);
    return await fetchWrapper.post(`${baseUrl}rewards/rewards`, formData);
}

async function setSessionOrders(params) {
    let formData = new URLSearchParams();
    let cartItems = params.cartItems;
    let customerData = params.selectedAddress;
    let paymentsData = params.payments;
    
    Object.keys(cartItems).forEach((key) => {
        for (var items in cartItems[key]) {
            if (items == 'modifiers') {
                let mods = cartItems[key][items];
                for (var mod in mods) {
                    Object.keys(cartItems[key][items][mod]).forEach((idx, modVal) => {
                        formData.append(`cartItems[${key}][${items}][${mod}][${idx}]`, mods[mod][idx]);
                    });
                }
            } else {
                formData.append(`cartItems[${key}][${items}]`, cartItems[key][items]);
            }
        }
    });

    Object.keys(customerData).forEach((k) => {
        formData.append(`selectedAddress[${k}]`, customerData[k]);
    })

    formData.append('pid', params.pid)
    formData.append('tips', params.tips)
    formData.append('promoCode', params.promoCode)
    formData.append('total', params.total)
    formData.append('subTotal', params.subTotal)
    formData.append('instructions', params.instructions)
    formData.append('orderProcessingType', params.orderProcessingType)
    formData.append('orderProcessingDate', params.orderProcessingDate)
    formData.append('order_id', params.orderId)

    Object.keys(paymentsData).forEach((key) => {
        for (var items in paymentsData[key]) {
            formData.append(`payments[${key}][${items}]`, paymentsData[key][items]);
        }
    });

    return await fetchWrapper.post(`${baseUrl}rewards/${siteId}/saveOrders`, formData);
}

async function handleRewardsPayment(params) {
    let formData = new URLSearchParams();
    formData.append('order_id', params.order_id);
    formData.append('user_id', params.user_id);
    
	return await fetchWrapper.post(`${baseUrl}rewards/${siteId}/paymentAndSendtoEconnect`, formData);
}


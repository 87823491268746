import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/storage";
import "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyB3KvqdQ2l_PhIvboNpzhykQ5EicKf7lT8",
  authDomain: "servingintel-2ef1f.firebaseapp.com",
  projectId: "servingintel-2ef1f",
  storageBucket: "servingintel-2ef1f.appspot.com",
  messagingSenderId: "599086490579",
  appId: "1:599086490579:web:f55136f21ed7a5d2b28526"
};

firebase.initializeApp(firebaseConfig);

export const auth = firebase.auth();

export const db = firebase.firestore();

export const storage = firebase.storage();

// export const messaging = firebase.messaging();

export default firebase;

const GoogleProvider = new firebase.auth.GoogleAuthProvider();
GoogleProvider.setCustomParameters({ prompt: "select_account" });
export const signInWithGoogle = async () =>
  auth.signInWithPopup(GoogleProvider).then(({ user }) => {
    console.log(user, "user");
    db.collection("users")
      .doc(user.uid)
      .onSnapshot((snapshot) => {
        console.log(snapshot.exists);
        if (!snapshot.exists && user.email !== "eliasdavidceriola@gmail.com") {
          db.collection("users").doc(user.uid).set({
            name: user.displayName,
            userType: "CUSTOMER",
            email: user.email,
          });
        }
      });

    return;
  });

import React, { Fragment, useEffect, useState } from "react";
import { NavBar } from "../navigation/NavBar";
import { BottomNavRestaurant } from "./Restaurant";
import { useRouteMatch, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Typography, Divider, useMediaQuery, Button } from "@material-ui/core";
import { setCart } from "../../../redux/cartReducer/actions";

export const Favorites = () => {
  const match = useRouteMatch();
  const history = useHistory();
  const [favorites, setFavorites] = useState([]);
  const isMobile = useMediaQuery("(max-width:600px)");
  const dispatch = useDispatch();

  useEffect(() => {
    const storedFavorites = localStorage.getItem(
      `favorites_${match.params.restaurantId}`
    );
    if (storedFavorites) {
      setFavorites(JSON.parse(storedFavorites));
    }
  }, []);

  return (
    <Fragment>
      <NavBar />

      <Typography
        variant="subtitle1"
        style={{ margin: "1rem 0rem 0rem 1rem", fontWeight: 800 }}
      >
        FAVORITES
      </Typography>
      <Divider />
      {favorites.map((product) => {
        return (
          <Fragment key={product.id}>
            <div
              style={{
                display: "flex",
                alignItems: "flex-start",
                margin: "1rem 0rem",
                paddingLeft: "1rem",
              }}
              onClick={() =>
                history.push(
                  `/menu/${product.productName}/${match.params.restaurantId}/${product.id}`
                )
              }
            >
              <div style={{ marginRight: "1rem" }}>
                <img
                  style={{ maxWidth: "100px" }}
                  src={product.image}
                  alt={product.productName}
                />
              </div>
              <div>
                <Typography>{product.productName}</Typography>
              </div>
            </div>
            <Divider />
          </Fragment>
        );
      })}
      {isMobile && <BottomNavRestaurant />}
    </Fragment>
  );
};

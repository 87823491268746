import React, { useEffect, useState } from "react";
import { db } from "../../firebase/config";
import {
  Paper,
  Typography,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@material-ui/core";
import { toast } from "react-toastify";
import { Form, Input } from "../../components";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
import * as Yup from "yup";
import AddIcon from "@material-ui/icons/Add";
import BackspaceIcon from "@material-ui/icons/Backspace";
import { useSelector, useDispatch } from "react-redux";
import { setLoading } from "../../redux/publicReducer/actions";

const CategoryModal = ({ open, setOpen }) => {
  const { user } = useSelector((state) => state.public);
  const dispatch = useDispatch();
  const categoryRef = db
    .collection("admins")
    .doc(user.id)
    .collection("categories");

  const initialValues = {
    name: "",
    description: "",
  };

  const validationSchema = Yup.object({
    name: Yup.string().required("Enter an option"),
    description: Yup.string(),
  });

  const methods = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
  });
  const handleSubmit = (data) => {
    console.log(data);
    setOpen(false);
    dispatch(setLoading(true));
    categoryRef.add({ ...data }).then(() => {
      toast.success("Category added");
      dispatch(setLoading(false));
    });
  };
  return (
    <React.Fragment>
      <Dialog
        open={open}
        maxWidth="md"
        fullWidth
        onClose={() => {
          setOpen(false);
        }}
      >
        <Form provider={methods} onSubmit={handleSubmit}>
          <DialogTitle>Adding a Category</DialogTitle>
          <DialogContent>
            <div>
              <Input name="name" placeholder="Enter category's name" />
              <Input name="description" placeholder="Enter Description" />
            </div>
          </DialogContent>
          <DialogActions>
            <Button type="submit" color="primary" variant="contained">
              Save Category
            </Button>
          </DialogActions>
        </Form>
      </Dialog>
    </React.Fragment>
  );
};

export const Categories = ({}) => {
  const { user } = useSelector((state) => state.public);

  const categoryRef = db
    .collection("admins")
    .doc(user.id)
    .collection("categories");
  const [openModal, setOpenModal] = useState(false);
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    const unsub = categoryRef.onSnapshot((snap) => {
      const _categories = snap.docs.map((doc) => {
        return {
          ...doc.data(),
          id: doc.id,
        };
      });
      setCategories(_categories);
    });

    return unsub;
  }, []);
  return (
    <div>
      <Button
        variant="contained"
        color="primary"
        onClick={() => {
          setOpenModal(true);
        }}
      >
        Add Category
      </Button>
      {categories.map((category) => {
        return (
          <Paper style={{ margin: "1rem 0rem", padding: "1rem" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography>{category.name}</Typography>
              <IconButton
                onClick={() => {
                  categoryRef
                    .doc(category.id)
                    .delete()
                    .then(() => {
                      toast.success("Category deleted");
                    });
                }}
              >
                <BackspaceIcon color="secondary" />
              </IconButton>
            </div>
          </Paper>
        );
      })}
      <CategoryModal open={openModal} setOpen={setOpenModal} />
    </div>
  );
};

import React, { useState, useLayoutEffect } from "react";
import { useRouteMatch, useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import GridList from "@material-ui/core/GridList";
import GridListTile from "@material-ui/core/GridListTile";
import GridListTileBar from "@material-ui/core/GridListTileBar";
import IconButton from "@material-ui/core/IconButton";
import StarBorderIcon from "@material-ui/icons/StarBorder";
import { Typography, useMediaQuery } from "@material-ui/core";
import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden",
    backgroundColor: theme.palette.background.paper,
  },
  gridList: {
    flexWrap: "nowrap",
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: "translateZ(0)",
  },
  title: {
    color: "white",
  },
  titleBar: {
    background:
      "linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)",
  },
}));

export const FavoritesSlider = () => {
  const match = useRouteMatch();
  const [favorites, setFavorites] = useState([]);
  const classes = useStyles();
  const isMobile = useMediaQuery("(max-width:600px)");
  const history = useHistory();

  useLayoutEffect(() => {
    const hasFavoritesInResto = localStorage.getItem(
      `favorites_${match.params?.restaurantId}`
    );

    if (hasFavoritesInResto) {
      setFavorites(JSON.parse(hasFavoritesInResto));
    }
  }, [match.params]);

  if (!favorites.length) {
    return null;
  }
  return (
    <>
      <Typography variant="h5" style={{ fontWeight: 800, margin: "1rem" }}>
        Your Favorites
      </Typography>
      <div className={classes.root}>
        <GridList className={classes.gridList} cols={isMobile ? 1.5 : 3}>
          {favorites.map((favorite) => (
            <GridListTile
              key={favorite.image}
              onClick={() =>
                history.push(
                  `/menu/${favorite.productName}/${match.params.restaurantId}/${favorite.id}`
                )
              }
            >
              <img src={favorite.image} alt={favorite.productName} />
              <GridListTileBar
                title={favorite.productName}
                classes={{
                  root: classes.titleBar,
                  title: classes.title,
                }}
                actionIcon={
                  <IconButton aria-label={`star ${favorite.productName}`}>
                    <FavoriteBorderIcon className={classes.title} />
                  </IconButton>
                }
              />
            </GridListTile>
          ))}
        </GridList>
      </div>
    </>
  );
};

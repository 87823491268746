import React, { Fragment } from "react";
import { Route } from "react-router-dom";
import { Crud } from "./Crud";
import { AdminMessagerList, AdminMessaging } from "./messaging";
import { AdminDrawer } from "./components";
import { adminRouteList } from "./constants/route";
import { AdminChatIcon } from "./AdminChatIcon";

export const AdminRoutes = () => {
  return (
    <Fragment>
      <AdminDrawer>
        {adminRouteList.map((route) => {
          return (
            <Route
              exact={route.route !== "/settings"}
              path={route.route}
              component={() => route.component}
            />
          );
        })}
        {/* <Route exact path={"/admin"} component={Crud} /> */}
        <Route path="/admin-messaging-list" component={AdminMessagerList} />
        <Route path="/chat/:chatId" component={AdminMessaging} />
        <AdminChatIcon />
      </AdminDrawer>
    </Fragment>
  );
};

import React, { useState, useEffect } from "react";
import { db } from "../../../firebase/config";
import { useRouteMatch, useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import GridList from "@material-ui/core/GridList";
import GridListTile from "@material-ui/core/GridListTile";
import GridListTileBar from "@material-ui/core/GridListTileBar";
import IconButton from "@material-ui/core/IconButton";
import StarBorderIcon from "@material-ui/icons/StarBorder";
import { Typography, useMediaQuery } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { setLoading } from "../../../redux/publicReducer/actions";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden",
    backgroundColor: theme.palette.background.paper,
  },
  gridList: {
    flexWrap: "nowrap",
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: "translateZ(0)",
  },
  title: {
    color: "white",
  },
  titleBar: {
    background:
      "linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)",
  },
}));

export const BestSeller = () => {
  const match = useRouteMatch();
  const orderRef = db
    .collection("orders")
    .where("restaurantId", "==", match.params.restaurantId);
  const [bestSellers, setBestSellers] = useState([]);
  const classes = useStyles();
  const history = useHistory();
  const isMobile = useMediaQuery("(max-width: 600px)");
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setLoading(true));
    const unsub = orderRef.onSnapshot((snapshot) => {
      let _bestSellers = snapshot.docs?.map((doc) => {
        return {
          ...doc.data(),
          id: doc.id,
        };
      });

      let ratedProducts = [];

      _bestSellers.forEach((sell) => {
        sell.orders.forEach((order) => {
          const existing = ratedProducts.find(
            (product) => product.id === order.id
          );
          if (!existing) {
            ratedProducts.push({ ...order, rating: order.quantity });
          } else {
            ratedProducts = ratedProducts.map((p) => {
              if (p.id === order.id) {
                return {
                  ...p,
                  rating: p.rating + order.quantity,
                };
              } else {
                return p;
              }
            });
          }
        });
      });
      console.log(_bestSellers, ratedProducts, "ratings");
      setBestSellers(ratedProducts.sort((a, b) => b.rating - a.rating));
      dispatch(setLoading(false));
    });

    return unsub;
  }, []);

  if (!bestSellers.length) {
    return null;
  }

  return (
    <>
      <Typography variant="h5" style={{ fontWeight: 800, margin: "1rem" }}>
        Our Best Sellers
      </Typography>
      <div className={classes.root}>
        <GridList className={classes.gridList} cols={isMobile ? 1.5 : 3}>
          {bestSellers.map((favorite) => (
            <GridListTile
              key={favorite.image}
              onClick={() =>
                history.push(
                  `/menu/${favorite.productName}/${match.params.restaurantId}/${favorite.id}`
                )
              }
            >
              <img src={favorite.image} alt={favorite.productName} />
              <GridListTileBar
                title={favorite.productName}
                classes={{
                  root: classes.titleBar,
                  title: classes.title,
                }}
                actionIcon={
                  <IconButton aria-label={`star ${favorite.productName}`}>
                    <StarBorderIcon className={classes.title} />
                  </IconButton>
                }
              />
            </GridListTile>
          ))}
        </GridList>
      </div>
    </>
  );
};

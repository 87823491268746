import ReactPlayer from "react-player";
import "../../../App.css";
import { Container, Card, Row, Col, h5, p, h2, div } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


export const OrderSlider = () => {
  return (
      
<div className="OrderSlider">
      <div className="OrderHeader">
        <Container>
          <Row>
            <col className="whitespace"></col>
            <Col sm={12} lg={8}>
              <h1 className="left titlecolor">
                Come check out our newest location.
              </h1>
                <h5 className="white">
                {" "}
                We are excited to announce our Saginaw location on Bay Rd. is Now Open!!! Our Drive-Thru is also available for quick service
              </h5>
              <h1 className="left titlecolor whitespace">
                Can't find your location?
              </h1>
              <h5 className="white">
                {" "}
                Please continue to call us to order all your Leo's favorites. 
              </h5>                
            </Col>
            <Col sm={0} lg={4}>
    {/*<Card>
        <Card.Img src="/static/1.jpg" />
    </Card> */}
            </Col>
           <col className="whitespace"></col>
          </Row>
        </Container>
      </div>
</div>
    

  );
};

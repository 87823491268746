import ReactPlayer from "react-player";
import "../../../App.css";
import { Container, Row, Col, h5, Button, p, h2, div } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


export const ContactSlider = () => {
  return (
      
<div className="ContactSlider">
      <div className="ContactHeader">
        <Container>
          <Row>
            <col className="whitespace"></col>
            <Col xs={12} sm={8} md={7} >
                <h5 className="white">
                {" "}
                Service Options: Dine-in  |  Curbside pickup
              </h5>
              <h1 className="left white">
                American (Traditional),
                Burgers, Gluten-Free
              </h1>

              <h5 className="white blankspace">
                {" "}
                Food for your soul! Located in Saint Charles & opening up a new location in South Elgin soon!
              </h5>
              <h5 className="white blankspace">
                {" "}
                86% Food was good  |  89% Delivery was on time  |  94% Order was correct
              </h5> 
              <Button className="btn-red whitespace margin-bottom-10" href="https://ajsliders.com/register" >
                  Join our Loyalty Program{" "}
                </Button>
            </Col>
            <Col xs={12} sm={4} md={5} className="PhonerightImg">
            </Col>

           <col className="whitespace"></col>
          </Row>
        </Container>
      </div>
</div>
    

  );
};

import { Container, Row, Col, Button, h5, p, h2, div } from "react-bootstrap";
// import "../../../../../App.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import center from '../../../assets/css/custom.css';
import { MenuNavigation } from '../components/MenuNavigation';
import {Footer} from '../components/Footer';
import React, { useEffect } from 'react'

export const TermsandCondition = () => {
    
useEffect(() => {
document.title = "Terms and Condition"
}, [])    
   
  return (
    <div>
      <div className="HeaderImg">
      <MenuNavigation/>
        <Container>
          <Row>
            <col className="whitespace"></col>
            <Col xs={12}>
              <h2 className="center white ">Terms and Condition</h2>
            </Col>
            <Col xs={10} className="center">
              <h5 className="white"></h5>
            </Col>

           <col className="whitespace"></col> 
          </Row>
        </Container>
      </div>

      <Container className="showcase">
        <Row className="left">
          <Col xs={12}>
            <h3>Refund / Return Policy </h3>
            <p>This policy applies to walk-in and call-in customer/third-party delivery orders only. </p>
            <p>To provide the best customer satisfaction, we provide the following solutions. Feel free to contact us if you have any questions regarding the Return & Refund Policy by emailing us at the restaurant. For immediate response, please call the restaurant or your third-party delivery company. Preparation of your order can begin immediately after your order has been confirmed. We cannot accept cancellations once your order has been confirmed with the restaurant. </p>
            <h3>Food Order Errors* </h3>
            <p>If you receive food that is different from your receipt, we sincerely apologize. Please call us or your third-party delivery company as soon as you notice that there was an error in your order or contact your third-party delivery company. You may come to pick up the correct food item. </p>
            <p>For credit card payments, you will be refunded the sales price amount associated with the error and recharged for the new items price. </p>
            <p>For cash payments, you will be asked to pay the difference of the balance if the new food has a greater value than the food received in error. In the same way, you will receive the difference of the balance back as credit for the new item if less than the food received in error. In some cases, we may offer you a store credit. </p>
            <p>Your order will be priority if you come to pick it up. In all cases, please return the food order in the original container(s)* to our host. </p>
            <h3>Food Order Incomplete* </h3>
            <p>In the rare occasion that you do not receive food that is on your receipt, we will make it up to you. Please call us or your third-party delivery service as soon as you notice that any food items were not received in your order. You may cancel the missed food before we prepare it without any question, and we will refund the amount to a credit card or we will refund you with a store credit. No cash refunds. </p>
            <p><b>Please note: </b>  We do provide courtesy items such as disposable silverware, chopsticks, etc. when requested for free. If we mistakenly omit a free courtesy item you are welcome to let us know so we may address the issue with our staff, however, we cannot provide discounts, refunds or store credit. </p>

            <h3>Food Dissatisfaction*</h3>
            <p>We cook our food fresh to order with only the finest and freshest ingredients. We take great care and pride in all of the dishes we make. Please call us immediately if you receive unsatisfactory food caused by a dislike or objects in the food. We will need the food returned in the original container(s)* so we may investigate and deal with the issue. We will prepare you a new food order. If you do not wish to receive a new dish, we may refund the amount to a credit card or we will refund you with a store credit only after we receive the food in the original container(s)* and have confirmed the error to the discretion of the manager on duty. </p>
            <h3>Cancel Order</h3>
            <p>Sorry, we cannot cancel, refund or give store credit if you change your mind or mistakenly order an item after the order is prepared. We cannot provide any refund or store credit for non-food items or beverages. </p>
            <h3>Order Cancelled Delivery Partner </h3>
            <p>It is possible your delivery partner might cancel the delivery if they are unable to find or reach you. When they arrive at your delivery address, they are prompted to contact you so it’s a good idea to keep your phone nearby when you’re expecting the food to arrive. If a delivery partner made a reasonable effort to contact you after arriving at your requested location, you may not be eligible for a refund. </p>
            <h3>Complimentary Food </h3>
            <p>Sorry, we cannot provide a refund or cash value on any complimentary food. </p>
            <p>As a private business, it is the right of the restaurant to deny or refuse service to any customer for any reason. If a customer abuses any of the Return & Refund Policy we, unfortunately, will not be able to conduct future business with the customer so that we may continue to provide excellent and quality food and service for you and our other wonderful and frequent patrons. </p>
            <p><b>Please note:</b> We strive to prepare and package our pick up items to preserve the high quality of the food. Keep in mind the temperature, sauce, and consistency of some items may vary slightly after being packaged.
            </p>
            
          </Col>

          <col className="whitespace"></col>
        </Row>
      </Container>
      <Footer/>
    </div>
  );
};

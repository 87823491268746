import * as React from 'react';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';

export default function CardMediaJoin() {
  return (
    <Card sx={{ maxWidth: 767 }}>
      <CardMedia
            component='video'
            image={"https://ajsliders.com/videos/AJS-why-join.mp4"}
            autoPlay
      />
    </Card>
  );
}
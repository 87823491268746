import React from 'react';

export const AddressMap = () => {
    return (
        <div className="google-map-code">
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2969.5018580071815!2d-88.33940698455807!3d41.90356907922015!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x880ee2a696133b41%3A0x803eb8f645bae735!2s2075%20Prairie%20St%20STE%20110%2C%20St.%20Charles%2C%20IL%2060174%2C%20USA!5e0!3m2!1sen!2sph!4v1636559321447!5m2!1sen!2sph" width="100%" height="450" frameborder="0" style={{border:0}} allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
        </div>
    );
}
 


import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { storage, db } from "../../firebase/config";
import { Form, Input } from "../../components";
import * as Yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Select from "react-select";
import { toast } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";
import { setLoading } from "../../redux/publicReducer/actions";
export default function ProductModal({
  open,
  setOpen,
  selectedProduct,
  setSelectedProduct,
}) {
  const { user } = useSelector((state) => state.public);
  const categoryRef = db
    .collection("admins")
    .doc(user.id)
    .collection("categories");
  const [productData, setProductData] = useState({});
  const [modifiers, setModifiers] = useState([]);
  const [progress, setProgress] = useState(0);
  const [categories, setCategories] = useState([]);
  const dispatch = useDispatch();
  const handleChange = async (e) => {
    e.preventDefault();
    setProductData({ ...productData, [e.target.name]: e.target.value });
  };

  const initialValues = {
    productName: "",
    price: "",
    image: "",
    modifiers: [],
    description: "",
    category: [],
  };

  const validationSchema = Yup.object({
    productName: Yup.string().required("Please enter the product name"),
    price: Yup.number().required("Price is required"),
    image: Yup.mixed().nullable(),
    modifiers: Yup.array().of(
      Yup.object({
        name: Yup.string(),
        price: Yup.number(),
      })
    ),
    description: Yup.string(),
    category: Yup.mixed(),
  });

  const methods = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
  });

  const { register, reset } = methods;

  const handleSubmit = async (data) => {
    setOpen(false);
    console.log(data);
    dispatch(setLoading(true));
    if (!selectedProduct.id) {
      const storageRef = storage.ref(data?.image[0].name);
      storageRef.put(data.image[0]).on(
        "state_changed",
        (snap) => {
          let percentage = (snap.bytesTransferred / snap.totalBytes) * 100;
          setProgress(percentage);
        },
        (err) => {},
        async () => {
          const url = await storageRef.getDownloadURL();
          db.collection("admins")
            .doc(user.id)
            .collection("products")
            .add({ ...data, image: url })
            .then((res) => {
              console.log(res.data);

              dispatch(setLoading(false));
              toast.success(
                `${selectedProduct.productName || "Product"} added`
              );
            });
        }
      );
    } else {
      db.collection("admins")
        .doc(user.id)
        .collection("products")
        .doc(selectedProduct.id)
        .set({
          ...data,
          image: selectedProduct.image,
        })
        .then((res) => {
          dispatch(setLoading(false));
          toast.success(`${selectedProduct.productName} Edited`);
        });
    }
  };

  useEffect(() => {
    if (selectedProduct?.productName) {
      console.log(selectedProduct);
      setModifiers(selectedProduct?.modifiers || []);
      reset({ ...selectedProduct, image: "" });
    } else {
      reset(initialValues);
    }

    return () => {
      setModifiers([]);
    };
  }, [selectedProduct]);

  useEffect(() => {
    const unsub = categoryRef.onSnapshot((snapshot) => {
      const _categories = snapshot.docs.map((doc) => {
        return {
          label: doc.data().name,
          value: doc.id,
          description: doc.data().description,
        };
      });

      setCategories(_categories);
    });

    return unsub;
  }, []);

  return (
    <div>
      <Dialog
        maxWidth="md"
        fullWidth
        open={open}
        onClose={() => {
          setOpen(false);
          setSelectedProduct({});
        }}
      >
        <Form provider={methods} onSubmit={handleSubmit}>
          <DialogTitle>
            {!selectedProduct?.productName
              ? "Adding a product"
              : `Edit ${selectedProduct.productName}`}
          </DialogTitle>
          <DialogContent dividers>
            <Input name="productName" placeholder="Product name" />
            <Input type="number" name="price" placeholder="Product price" />
            <Input name="description" placeholder="Description" />
            <Controller
              as={<Select placeholder="Select Category" />}
              name="category"
              options={categories}
              control={methods.control}
              isMulti
            />

            {!selectedProduct?.productName && (
              <input
                style={{ marginTop: "1rem" }}
                required
                ref={register}
                name="image"
                type="file"
                // onChange={(e) => {
                //   setValue("image", e.target.files[0]);
                // }}
              />
            )}
            {modifiers?.map((mod, index) => {
              return (
                <div
                  style={{ display: "flex", alignItems: "center" }}
                  key={index}
                >
                  <Input
                    name={`modifiers[${index}].name`}
                    placeholder="Modifier's name"
                  />
                  <Input
                    name={`modifiers[${index}].price`}
                    type="number"
                    placeholder="Additional price"
                  />
                </div>
              );
            })}
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: "1rem",
              }}
            >
              <Typography>Add modifiers</Typography>
              <IconButton
                type="button"
                onClick={() => {
                  setModifiers([...modifiers, { name: "", price: "" }]);
                }}
              >
                <AddIcon />
              </IconButton>
            </div>
          </DialogContent>
          <DialogActions>
            <Button variant="contained" color="primary" type="submit">
              Save product
            </Button>
          </DialogActions>
        </Form>
      </Dialog>
    </div>
  );
}
